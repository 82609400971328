import React, { Component } from "react";
import Winner from "./Winner"

class WinnerLists extends Component{
    render() {
        let lists = (
            <div className="card">
                <div className="col-12 mx-auto">
                    <div className="alert-info text-center pt-5 pb-5">
                        ไม่มีผู้ถูกรางวัล
                    </div>
                </div>
            </div>
        );
        if (this.props.winners.length > 0){
            lists = this.props.winners
                .map(  item => 
                    <div
                    key={item.id}
                    >
                        <Winner data={item} />
                    </div>
                )
        }
        return (
            <div>
                {lists}
            </div>
        );    
    }
}

export default WinnerLists;