const initialState = {
    draws: [],
    rewards: [],
    isLoading: false,
    isCompleted: false
}
const reducer = (state = initialState, action) => {
    const allDraws = [...state.draws]
    switch (action.type) {
        case 'GET_DRAW_LISTS':
            const allDrawsState = {
                ...state,
                draws: action.payload
            }
            return allDrawsState;
        case 'GET_REWARD_LISTS':
            const allPrizessState = {
                ...state,
                rewards: action.payload
            }
            return allPrizessState;
        case 'DEL_DRAW':
            const newState = {
                ...state,
                draws: state.draws
                .filter(item => item.reference !== action.payload)
            }
            console.log("NEW STATE AFTER DEL", newState)
            return newState;
        case 'EDIT_DRAW_DATE':
            const indexForEdit = allDraws
            .findIndex((item) => {
                return item.reference === action.payload.reference
            })
            console.log('index for editing', indexForEdit);
            allDraws[indexForEdit] = {
                reference: action.payload.reference,
                date: action.payload.date,
            }
            const editedDrawDateState = {
                ...state,
                draws: allDraws
            }
            return editedDrawDateState;
        case 'LOADING_START':
            return {
                ...state,
                isLoading: true,
                isCompleted: false
            }
        case 'LOADING_END':
            return {
                ...state,
                isLoading: false,
            }
        case 'ADD_REWARD':
            return {
                ...state,
                isCompleted: true
            }
        case 'ADD_DRAW':
            return {
                ...state,
                isCompleted: true
            }
        case 'UPDATE_REWARD':
            return {
                ...state,
                isCompleted: true
            }
        default:
            break;
    }
    return state;
}
export default reducer