import React,{ Component } from "react";
import { connect } from "react-redux";
import * as action from "./action"
import { Link } from "react-router-dom";
import Reward from "./Reward";

class RewardLists extends Component {
    componentDidMount() {
        this.props.getAllRewards();
    }
    render() {
        const {rewardFromStore, loading } = this.props;

        let lists = (
            <div className="col-12 mx-auto">
                <div className="alert-info text-center pt-5 pb-5">
                    ไม่พบข้อมูล
                </div>
            </div>
        );

        if (rewardFromStore.length > 0) {
            lists = rewardFromStore
                .map( item => 
                    <div
                    className="col-12 mt-3"
                    key={item.reference}>
                        <Reward data={item} />
                    </div>
                );
        }
        if (loading === true) {
            return (
                <div
                    className="d-flex justify-content-center p-5"
                >
                    <div
                    className="spinner-grow text-secondary"
                    role="status"
                    >
                        <span className="visually-hidden">
                            loading ...
                        </span>
                    </div>

                </div>
            )
        }
        return (
            <div>
                <Link to={"/add/reward"}>
                    <button className="btn btn-success
                                btn-sm float-begin m-1 " >
                        เพิ่มของรางวัล
                    </button>
                </Link>
                <div className="row">
                    {lists}
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        rewardFromStore: state.rewards,
        Loading: state.isLoading
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAllRewards: () => {
            return dispatch(action.getRewardList());
        }
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(RewardLists);