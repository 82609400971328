import React from "react";

const RewardTag = (props) => {
    let header = (<h7>{props.prize}</h7>)
    if (props.result) {
        header = (<h7>{props.prize}   เลขที่ออก {props.result}</h7>)
    }
    return (
        <div className="card  text-black">
            <div className="card-header bg-info">
                {header}
            </div>
            <div className="card-body">
                <p>รางวัล {props.reward} มูลค่า {props.value} บาท</p>
            </div>

        </div>
    )
}

export default RewardTag;