import React, {Component} from "react";
import { connect } from "react-redux"
import { useParams } from 'react-router-dom'
import { useNavigate } from "react-router-dom";
import * as action from "./action";

class EnterPrize extends Component {
    state = {
        big_number_prize: null,
        big_number_valid: false,
        upper_left_triple_digits_prize: null,
        upper_left_triple_digits_valid: false,
        lower_left_triple_digits_prize: null,
        lower_left_triple_digits_valid: false,
        upper_right_triple_digits_prize: null,
        upper_right_triple_digits_valid: false,
        lower_right_triple_digits_prize: null,
        lower_right_tirple_digits_valid: false,
        two_digits_prize: null,
        two_digits_valid: false,
        id: 0,
        wait: false
    }

    componentDidMount() {
        const id = this.props.params.id
        this.setState({
            id: id
        })
    }

    onSubmitDrawForm = (data, event) => {
        event.preventDefault();
        console.log("SUBMIT", data);
        if(data.big_number_valid === false) {
            alert("รางวัลที่หนึ่งต้องมี 6 หลัก");
            return;
        }
        if(data.upper_left_triple_digits_valid === false) {
            alert("รางวัลเลขหน้าสามตัวครั้งที่ 1 ต้องมี 3 หลัก");
            return;
        }
        if(data.lower_left_triple_digits_valid === false) {
            alert("รางวัลเลขหน้าสามตัวครั้งที่ 2 ต้องมี 3 หลัก");
            return;
        }
        if(data.upper_right_digits_valid === false) {
            alert("รางวัลเลขท้ายสามตัวครั้งที่ 1 ต้องมี 3 หลัก");
            return;
        }
        if(data.lower_right_digits_valid === false) {
            alert("รางวัลเลขท้ายสามตัวครั้งที่ 2 ต้องมี 3 หลัก");
            return;
        }
        if(data.two_digits_valid === false) {
            alert("รางวัลเลขท้ายสองตัวต้องมี 2 หลัก");
            return;
        }

        const prize = {
            "reference": data.id,
            "big_number_prize": data.big_number_prize,
            "upper_left_triple_digits_prize": data.upper_left_triple_digits_prize,
            "lower_left_triple_digits_prize": data.lower_left_triple_digits_prize,
            "upper_right_triple_digits_prize": data.upper_right_triple_digits_prize,
            "lower_right_triple_digits_prize": data.lower_right_triple_digits_prize,
            "two_digits_prize": data.two_digits_prize
            
        };

        this.props.addPrizeAtStore(prize);
        this.setState({
            wait: true
        })
        setTimeout(
            () => { this.props.navigate('/'); }, 3000
        );
        
    }

    onBigNumberChange = (data) => {
        if (data.target.value.length === 6) {
            this.setState({
                "big_number_prize":data.target.value,
                "big_number_valid":true
            })    
        } else{
            this.setState({
                "big_number_valid":false
            })
        }
    }

    onUpperLeftTripleDigitsChange = (data) => {
        if (data.target.value.length === 3) {
            this.setState({
                "upper_left_triple_digits_prize": data.target.value,
                "upper_left_triple_digits_valid": true
            })    
        } else{
            this.setState({
                "upper_left_triple_digits_valid": false
            })
        }
    }

    onLowerLeftTripleDigitsChange = (data) => {
        if (data.target.value.length === 3) {
            this.setState({
                "lower_left_triple_digits_prize": data.target.value,
                "lower_left_triple_digits_valid": true
            })
    
        } else{
            this.setState({
                "lower_left_triple_digits_valid": false
            })
        }
    }

    onUpperRightTripleDigitsChange = (data) => {
        if (data.target.value.length === 3) {
            this.setState({
                "upper_right_triple_digits_prize": data.target.value,
                "upper_right_triple_digits_valid": true
            })    
        } else{
            this.setState({
                "upper_right_digits_digits_valid": false
            })
        }
    }

    onLowerRightTripleDigitsChange = (data) => {
        if (data.target.value.length === 3) {
            this.setState({
                "lower_right_triple_digits_prize": data.target.value,
                "lower_right_triple_digits_valid": true
            })    
        } else{
            this.setState({
                "lower_right_triple_digits_valid": false
            })
        }
    }

    onTwoDigitsChange = (data) => {
        if (data.target.value.length === 2) {
            this.setState({
                "two_digits_prize": data.target.value,
                "two_digits_valid": true
            })
    
        } else{
            this.setState({
                "two_digits_valid": false
            })
        }
    }


    render() {
        if (this.state.wait) {
            return(
                <div className="col-12 mx-auto">
                    <div
                        className="alert-info text-center pt-5 pb-5"
                    >กำลังประมวลผล</div>
                </div>
    
            )
        }

        return (
            <div className="row mt-3 mb-5 mx-auto bg-light">
                <div className="card col-12 col-sm-8 col-lg-6 mx-auto">
                    <div className="card-body">

                        <div className="block-4 text-center">
                            <img 
                                className="img img-thumbnail mt-5 mb-3"
                                src="https://ramitabeauty.com/wp-content/uploads/2020/06/logo-ramita-001r.png"
                                alt="โลโก้"
                            />
                            <h4>การป้อนผลรางวัล</h4>
                        </div>

                        <form
                        onSubmit={
                            this.onSubmitDrawForm.bind(this, this.state)
                        }>
                            <div className="form-group">
                                <div className="form-outline">
                                    <label className="form-label" for="typeNumber" >รางวัลที่หนึ่ง</label>
                                    <input type="number"  className="form-control" onChange={ this.onBigNumberChange}/>
                                </div>
                                <div className="form-outline">
                                    <label className="form-label" for="typeNumber">รางวัลเลขหน้าสามตัวครั้งที่ 1</label>
                                    <input type="number" className="form-control" onChange={ this.onUpperLeftTripleDigitsChange}/>
                                </div>
                                <div className="form-outline">
                                    <label className="form-label" for="typeNumber">รางวัลเลขท้ายสามตัวครั้งที่ 2</label>
                                    <input type="number" className="form-control" onChange={ this.onLowerLeftTripleDigitsChange}/>
                                </div>
                                <div className="form-outline">
                                    <label className="form-label" for="typeNumber">รางวัลเลขท้ายสามตัวครั้งที่ 1</label>
                                    <input type="number" className="form-control" onChange={ this.onUpperRightTripleDigitsChange}/>
                                </div>
                                <div className="form-outline">
                                    <label className="form-label" for="typeNumber">รางวัลเลขท้ายสามตัวครั้งที่ 2</label>
                                    <input type="number" className="form-control" onChange={ this.onLowerRightTripleDigitsChange}/>
                                </div>
                                <div className="form-outline">
                                    <label className="form-label" for="typeNumber">รางวัลเลขท้ายสองตัว</label>
                                    <input type="number" className="form-control" onChange={ this.onTwoDigitsChange}/>
                                </div>

                                <div className="form-group text-center pt-3">
                                    <button
                                        className="btn btn-outline-success btn-sm mx-1" 
                                        onClick={() => { this.props.navigate('/')}}
                                        >
                                            ยกเลิก
                                    </button>
                                    <button
                                        type="submit"
                                        className="btn btn-sm btn-primary mx-1" >
                                            ตกลง
                                    </button>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addPrizeAtStore: (prize) => {
            return dispatch(action.addPrize(prize));
        },

    }
}
export default connect(null, mapDispatchToProps)(
    function(props){
        const params = useParams();
        const navigate = useNavigate();

        return <EnterPrize {...props} params={params}  navigate={navigate}/>;
    }
)