import React,{ Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as action from "./action"
import RewardTag from "./RewardTag";
import WinnerLists from "./WinnerLists";


class Draw extends Component {

    render() {
        var date = new Date(this.props.data.date).toDateString();
        let big_number_tag = (<RewardTag prize = "รางวัลที่หนึ่ง" reward={this.props.data.big_number_reward} value={this.props.data.big_number_value}/>);
        let upper_left_triple_digits_tag = (<RewardTag prize = "รางวัลเลขหน้าสามตัวครั้งที่หนึ่ง" reward={this.props.data.upper_left_triple_digits_reward} value={this.props.data.upper_left_triple_digits_value}/>);
        let lower_left_triple_digits_tag = (<RewardTag prize = "รางวัลเลขหน้าสามตัวครั้งที่สอง" reward={this.props.data.lower_left_triple_digits_reward} value={this.props.data.lower_left_triple_digits_value}/>);
        let upper_right_triple_digits_tag = (<RewardTag prize = "รางวัลเลขท้ายสามตัวครั้งที่หนึ่ง" reward={this.props.data.upper_right_triple_digits_reward} value={this.props.data.upper_right_triple_digits_value}/>);
        let lower_right_triple_digits_tag = (<RewardTag prize = "รางวัลเลขท้ายสามตัวครั้งที่สอง" reward={this.props.data.lower_right_triple_digits_reward} value={this.props.data.lower_right_triple_digits_value}/>);
        let two_digits_tag = (<RewardTag prize = "รางวัลเลขท้ายสองตัว" reward={this.props.data.two_digits_reward} value={this.props.data.two_digits_value}/>);
        let extra_two_digits_tag = (<RewardTag prize = "รางวัลพิเศษเลขท้ายสองตัวของรางวัลที่หนึ่ง" reward={this.props.data.extra_two_digits_reward} value={this.props.data.extra_two_digits_value}/>);
        let winners = (<div></div>);
        let results = (<div></div>);
        if (this.props.data.is_active === false){
            big_number_tag = (<RewardTag prize = "รางวัลที่หนึ่ง" result={this.props.data.big_number} reward={this.props.data.big_number_reward} value={this.props.data.big_number_value}/>)
            upper_left_triple_digits_tag = (<RewardTag prize = "รางวัลเลขหน้าสามตัวครั้งที่หนึ่ง" result={this.props.data.upper_left_triple_digits} reward={this.props.data.upper_left_triple_digits_reward} value={this.props.data.upper_left_triple_digits_value}/>);
            lower_left_triple_digits_tag = (<RewardTag prize = "รางวัลเลขหน้าสามตัวครั้งที่สอง" result={this.props.data.lower_left_triple_digits} reward={this.props.data.lower_left_triple_digits_reward} value={this.props.data.lower_left_triple_digits_value}/>);
            upper_right_triple_digits_tag = (<RewardTag prize = "รางวัลเลขท้ายสามตัวครั้งที่หนึ่ง" result={this.props.data.upper_right_triple_digits} reward={this.props.data.upper_right_triple_digits_reward} value={this.props.data.upper_right_triple_digits_value}/>);
            lower_right_triple_digits_tag = (<RewardTag prize = "รางวัลเลขท้ายสามตัวครั้งที่หนึ่ง" result={this.props.data.lower_right_triple_digits} reward={this.props.data.lower_right_triple_digits_reward} value={this.props.data.lower_right_triple_digits_value}/>);
            two_digits_tag = (<RewardTag prize = "รางวัลเลขท้ายสองตัว" result={this.props.data.two_digits} reward={this.props.data.two_digits_reward} value={this.props.data.two_digits_value}/>);
            extra_two_digits_tag = (<RewardTag prize = "รางวัลพิเศษเลขท้ายสองตัวของรางวัลที่หนึ่ง" result={this.props.data.extra_two_digits} reward={this.props.data.extra_two_digits_reward} value={this.props.data.extra_two_digits_value}/>);

            winners = (
                <WinnerLists winners={this.props.data.winners_list} />
            )
            results = (
                <div className="card">
                    <div className="card-header bg-info text-white">
                        รายชื่อผู้ถูกรางวัล
                    </div>
                    <div className="card-body">
                        <div className="m-2">
                            {winners}
                        </div>

                    </div>
                </div>
            )
        }

        const delFn = this.props.deleteDrawAtStore;
        const id = this.props.data.reference;
        const isActive = this.props.data.is_active;
        const dateTH = new Date(date).toLocaleDateString('th-TH',  {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            weekday: 'long',
          });

        return (
            <div className="card bg-light text-black">
                <div className="card-header bg-primary text-white">
                    
                    <p>งวดวันที่: {dateTH} ---- จำนวนผู้เล่น : {this.props.data.players_count} ท่าน</p>
                    {
                        isActive &&
                        <button
                        className="btn btn-outline-danger
                        btn-sm float-end mx-1"
                        onClick={ ()=>delFn(id)}
                        >ลบ</button>
                    }

                    {
                        isActive &&
                        <Link to={ '/edit/date/' + id }>
                            <button 
                                className="btn btn-success
                                btn-sm float-end mx-1"
                            >แก้ไขวันออกรางวัล</button>
                        </Link>

                    }


                    {
                        (isActive === false) &&
                        <Link to={'/edit/prize/' + id}>
                            <button 
                                className="btn btn-success
                                btn-sm float-end mx-1"
                            >แก้ไขผลรางวัล</button>
                        </Link>

                    }

                    {
                        isActive &&
                        <Link to={'/enter/prize/' + id}>
                            <button 
                                className="btn btn-success
                                btn-sm float-end mx-1"
                            >ป้อนผลรางวัล</button>
                        </Link>

                    }

                </div>
                
                <div className="card-body">
                        {big_number_tag}
                        {upper_left_triple_digits_tag}
                        {lower_left_triple_digits_tag}
                        {upper_right_triple_digits_tag}
                        {lower_right_triple_digits_tag}
                        {two_digits_tag}
                        {extra_two_digits_tag}
                        {results}
                </div>

                
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        deleteDrawAtStore: (id) => {
            return dispatch(action.delDraw(id));
        }
    }
}

export default connect(null, mapDispatchToProps)(Draw);