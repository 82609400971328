import React,{ Component } from "react";
import { Link, NavLink } from "react-router-dom";

class Header extends Component {
    state = {
        collapse: false
    }
    render(){
        return (
            <ul className="nav navtab navtab-expand-lg navtab-light bg-primary mx-auto text-center">
                <li className="nav-item ">
                    <NavLink className="nav-link text-white" to="/">งวด</NavLink>

                </li>
                <li className="nav-item">
                    <NavLink className="nav-link text-white" to="/rewards">รางวัล</NavLink>
                </li>

                <button className="navbar-toggler"
                    type="button" data-toggle="collapse"
                    data-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded="true"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"
                        onClick={
                            () => {
                                this.setState(
                                    {
                                        collapse: !this.state.collapse
                                    }
                                );
                            }
                        }
                    ></span>
                </button>
            </ul>
        )
    }
}

export default Header