import React,{Component} from "react";

class PageNotFound extends Component {
    render() {
        return (
            <div className="raw mt-5 mb-5">
                <div className="col-10 text-center p5 mx-auto">
                    <h1 className="display-4 mb-4 pb-3">
                        <span className="text-danger">ERROR 404</span>
                        Page Not Found!!
                    </h1>
                </div>
            </div>
        )
    }
}

export default PageNotFound;