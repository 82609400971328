import React,{ Component } from "react";

class Winner extends Component{
    render() {
        return(
            <div className="card mt-1">
                <div className="card-header bg-success text-white">
                    ผู้ถูกรางวัล {this.props.data.prize}
                </div>
                <div className="card-body">
                    <p>ระหัสผู้เล่น:   {this.props.data.id}</p>
                    <p>หมายเลขที่เล่น:   {this.props.data.number}</p>
                    <p>วัน-เวลาที่เล่น:   {this.props.data.timestamp}</p>
                    <p>ข้อมูลติดต่อ เบอร์โทร หรือ Email:   {this.props.data.contact}</p>
                </div>
            </div>
        )
    }
}
export default Winner