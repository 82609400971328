import React, { Component } from "react";
import { connect } from "react-redux"
import * as action from "./action"
import { useParams } from "react-router-dom";
import DatePicker from "react-datepicker"
import { useNavigate } from 'react-router-dom'


class EditDrawDate extends Component {
    state = {
        id: '',
        date: '',
        wait: false
    }


    onChangeData = (event) => {
        this.setState(
            {
                [event.target.name]: event.target.value
            }
        )
    }

    componentDidMount() {
        

        const userId = this.props.params.id
        const draw = this.getCurrentDraw(userId);
        
        if (draw) {
            this.setState({
                id: draw.reference,
                date: Date.parse(draw.date)
            })
        } else{
           this.props.navigate('/');
        }
    }

    getCurrentDraw(id) {
        const allDraws = this.props.drawsFromStore;
        const [draw] = allDraws.filter((item) => {
            return item.reference.toString() === id.toString();
        });
        return draw;
    }

    onSubmitDrawForm = (data, event) => {
        event.preventDefault();
        console.log("data", data);
        const date = new Date(data.date)
        let txt_date = date.toUTCString()

        const newData = {
            id: this.state.id,
            date: txt_date
        }
        this.props.editDrawAtStore(newData);
        this.setState({
            wait: true
        })
        setTimeout(
            () => this.props.navigate('/'), 3000
        );
    }
    
    onTimeChange = (time) => {
        this.setState(
            {
                "date": time
            }
        )
    }

    render() {
        if (this.state.wait) {
            return(
                <div className="col-12 mx-auto">
                    <div
                        className="alert-info text-center pt-5 pb-5"
                    >กำลังประมวลผล</div>
                </div>
    
            )
        }

        return (
            <div className="card mt-3 mb-5 mr-5">
                <div className="card-body">
                    <form
                    onSubmit={
                        this.onSubmitDrawForm.bind(this, this.state)
                    }>

                        <div className="form-group text-center pt-3">

                            <label className="mb-1 ">Date</label>
                            <DatePicker
                                selected={this.state.date}
                                dateFormat="dd/MM/yyyy"
                                onChange={this.onTimeChange}
                            />

                            <button
                            onClick={
                                () =>  this.props.navigate('/')
                            }

                            className="btn btn-outline-success mx-1 mt-3">
                                ยกเลิก
                            </button>

                            <button
                            type="submit"
                            className="btn btn-primary mx-1 mt-3">
                                แก้ไข
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        drawsFromStore: state.draws
    }
}

const mapDispatchToProps = dispatch => {
    return {
        editDrawAtStore: (data) => {
            return dispatch(action.editDrawDate(data))
        }
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(
    function(props){
        const params = useParams();
        const navigate = useNavigate();

        return <EditDrawDate  {...props} params={params} navigate={navigate}/>
    }
)