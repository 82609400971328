import React, { Component } from "react";
import Draw from "./Draw"
import { connect } from "react-redux";
import * as action from "./action"
import { Link } from "react-router-dom";
import AddDraw from "./AddDraw";

class DrawLists extends Component {
    componentDidMount() {
        this.props.getAllDraws();
    }
    render() {
        const { drawFromStore, loading } = this.props;
        
        let lists = (
            <div className="col-12 mx-auto">
                <div
                className="alert-info text-center pt-5 pb-5"
                >ไม่พบข้อมูล</div>
            </div>
        );
        if (drawFromStore.length > 0) {
            lists = drawFromStore
                .map( item =>             
                        <div
                        className="col-12 mt-3"
                        key={item.reference}>
                            <Draw data={item} />
                        </div>
                        
                );
        }
        if (loading === true) {
            return (
                <div
                    className="d-flex justify-content-center p-5"
                >
                    <div
                    className="spinner-grow text-secondary"
                    role="status"
                    >
                        <span className="visually-hidden">
                            loading ...
                        </span>
                    </div>

                </div>
            )
        }
        return (
            <div>
                <Link to={"/add"}>
                    <button className="btn btn-success
                                btn-sm float-begin m-1 " >
                        เพิ่มงวด
                    </button>
                </Link>
                <div className="row">
                    {lists}
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        drawFromStore: state.draws,
        Loading: state.isLoading
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAllDraws: () => {
            return dispatch(action.getDrawList());
        }
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(DrawLists);