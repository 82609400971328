import React,{ Component } from "react";
import { connect } from "react-redux"
import { Link } from "react-router-dom";

class Reward extends Component {
    render() {
        const id = this.props.data.value;
        //console.log("REWARD", this.props.data);
        return (
            <div className="card bg-light text-black">
                <div className="card-header bg-info text-danger">
                    ของรางวัล: { this.props.data.label }

                        <Link to={ '/edit/reward/' + id }>
                            <button 
                                className="btn btn-success
                                btn-sm float-end mx-1"
                            >แก้ไขรางวัล</button>
                        </Link>
                </div>
                <div className="card-body">
                    <p>มูลค่า: { this.props.data.price } บาท</p>
                </div>

            </div>
        )
    }
}

export default connect(null, null)(Reward);