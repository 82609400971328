import React, { Component } from "react"
import { connect } from "react-redux"
import * as action from "./action"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { useNavigate } from 'react-router-dom'

class AddDraw extends Component {
    state = {
        date: null,
        big_number_reward: null,
        upper_left_triple_digits_reward: null,
        lower_left_triple_digits_reward: null,
        upper_right_triple_digits_reward: null,
        lower_right_triple_digits_reward: null,
        two_digits_reward: null,
        extra_two_digits_reward: null,
        rewards : [],
    };


    componentDidMount() {
        this.props.getAllRewards();
    }


    onBigNumberChange = (data) => {
        console.log("BIG", data);
        this.setState(
            {
                big_number_reward: data.value
            }
        )
    }

    onUpperLeftTripleDigitsChange = (data) => {
        this.setState(
            {
                upper_left_triple_digits_reward: data.value
            }
        )
    }

    onLowerLeftTripleDigitsChange = (data) => {
        this.setState(
            {
                lower_left_triple_digits_reward: data.value
            }
        )
    }

    onUpperRightTripleDigitsChange = (data) => {
        this.setState(
            {
                upper_right_triple_digits_reward: data.value
            }
        )
    }

    onLowerRightTripleDigitsChange = (data) => {
        this.setState(
            {
                lower_right_triple_digits_reward: data.value
            }
        )
    }

    onTwoDigitsChange = (data) => {
        this.setState(
            {
                two_digits_reward: data.value
            }
        )
    }

    onExtraTwoDigitsChange = (data) => {
        this.setState(
            {
                extra_two_digits_reward: data.value
            }
        )
    }

    onTimeChange = (time) => {
        this.setState(
            {
                "date": time
            }
        )
    }

    onSubmitDrawForm = (data, event) => {
        event.preventDefault();
        //console.log(data)
        if (data.date == null) {
            alert("ต้องกำหนดวันออกรางวัล");
            return;
        }
        if (data.big_number_reward == null) {
            alert("ต้องกำหนดของรางวัลสำหรับรางวัลทีหนึ่ง");
            return;
        }
        if (data.upper_left_triple_digits_reward == null) {
            alert("ต้องกำหนดของรางวัลสำหรับรางวัลเลขหน้าสามตัวครั้งที่ 1");
            return;
        }
        if (data.lower_left_triple_digits_reward == null) {
            alert("ต้องกำหนดของรางวัลสำหรับเลขหน้าสามตัวครั้งที่ 2");
            return;
        }
        if (data.upper_right_triple_digits_reward == null) {
            alert("ต้องกำหนดของรางวัลสำหรับเลขท้ายสามตัวครั้งที่ 1");
            return;
        }
        if (data.lower_right_triple_digits_reward == null) {
            alert("ต้องกำหนดของรางวัลสำหรับเลขท้ายสามตัวครั้งที่ 2");
            return;
        }
        if (data.two_digits_reward == null) {
            alert("ต้องกำหนดของรางวัลสำหรับเลขท้ายสองตัว");
            return;
        }
        if (data.extra_two_digits_reward == null) {
            alert("ต้องกำหนดของรางวัลสำหรับรางวัลพิเศษเลขท้ายสองตัวของรางวัลที่หนึ่ง");
            return;
        }
        const newData = {
            date: data.date,
            big_number_prize: data.big_number_reward,
            upper_left_triple_digits_prize: data.upper_left_triple_digits_reward,
            lower_left_triple_digits_prize: data.lower_left_triple_digits_reward,
            upper_right_triple_digits_prize: data.upper_right_triple_digits_reward,
            lower_right_triple_digits_prize: data.lower_right_triple_digits_reward,
            two_digits_prize: data.two_digits_reward,
            extra_two_digits_prize: data.extra_two_digits_reward
        }

        //console.log("NEW DATA: ", newData);

        this.props.addDrawAtStore(newData);
    }

    
    
    render() {
        const { rewardsFromStore, completed, loading } = this.props;

        if(completed) {
            this.props.navigate('/');
        }

        if(loading) {
            return(
                <div className="col-12 mx-auto">
                    <div
                        className="alert-info text-center pt-5 pb-5"
                    >กำลังประมวลผล</div>
                </div>
    
            )    
        }


        return (
            <div className="row mt-3 mb-5 mx-auto bg-light">
                <div className="card col-12 col-sm-8 col-lg-6 mx-auto">
                    <div className="card-body">
                        <form onSubmit = {
                            this.onSubmitDrawForm.bind(this, this.state)
                        }>
                            <div className="block-4 text-center">
                                <img 
                                    className="img img-thumbnail mt-5 mb-3"
                                    src="https://ramitabeauty.com/wp-content/uploads/2020/06/logo-ramita-001r.png"
                                    alt="โลโก้"
                                />
                            </div>
                            <div className="form-group ">
                                <label className="mt-1 ">Date</label>
                                <DatePicker
                                    selected={this.state.date}
                                    dateFormat="dd/MM/yyyy"
                                    onChange={this.onTimeChange}
                                />
                                

                                <label className="mt-2">รางวัลที่หนึ่ง</label>
                                <Dropdown options={rewardsFromStore} onChange={this.onBigNumberChange}  placeholder="..." />      
                                
                                <label className="mt-3">รางวัลเลขหน้าสามตัวครั้งที่ 1</label>
                                <Dropdown options={rewardsFromStore} onChange={this.onUpperLeftTripleDigitsChange}  placeholder="..." />      
                                
                                <label className="mt-3">รางวัลเลขหน้าสามตัวครั้งที่ 2</label>
                                <Dropdown options={rewardsFromStore} onChange={this.onLowerLeftTripleDigitsChange}  placeholder="..." />      
                                
                                <label className="mt-3">รางวัลเลขท้ายสามตัวครั้งที่ 1</label>
                                <Dropdown options={rewardsFromStore} onChange={this.onUpperRightTripleDigitsChange}  placeholder="..." />      
                                
                                <label className="mt-3">รางวัลเลขท้ายสามตัวครั้งที่ 2</label>
                                <Dropdown options={rewardsFromStore} onChange={this.onLowerRightTripleDigitsChange}  placeholder="..." />      
                                
                                <label className="mt-3">รางวัลเลขท้ายสองตัว</label>
                                <Dropdown options={rewardsFromStore} onChange={this.onTwoDigitsChange}  placeholder="..." />      
                                
                                <label className="mt-3">รางวัลพิเศษเลขท้ายสองตัวของรางวัลที่หนึ่ง</label>
                                <Dropdown options={rewardsFromStore} onChange={this.onExtraTwoDigitsChange}  placeholder="..." />      


                                <div className="form-group text-center pt-3">
                                    <button
                                        className="btn btn-outline-success btn-sm mx-1" 
                                        onClick={() => { this.props.navigate('/')}}
                                        >
                                            ยกเลิก
                                    </button>
                                    <button
                                        type="submit"
                                        className="btn btn-sm btn-primary mx-1" >
                                            เพิ่ม
                                    </button>
                                </div>
                            </div>    
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addDrawAtStore: (newDrawData) => {
            return dispatch(action.addDraw(newDrawData));
        },
        getAllRewards: () => {
            return dispatch(action.getRewardList());
        }

    }
}

const mapStateToProps = (state) => {
    return {
        rewardsFromStore: state.rewards,
        completed: state.isCompleted,
        loading: state.isLoading
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(
    
    function(props){
        const navigate = useNavigate();

        return <AddDraw {...props} navigate={navigate} />;
    }
)