import axios from "axios";

//const baseUrl = 'https://localhost:5000'
const baseUrl = 'https://lucky.ramitabeauty.com:9098'
export const delDraw = (id) => {
    const url = baseUrl + '/api/draw/delete/' + id;
    return async (dispatch) => {
        try{
            dispatch({type:'LOADING_START'});
            const res = await axios
            .delete(
                url
            );
            dispatch({
                type: 'DEL_DRAW',
                payload: res.data.id
            });
        } catch(error) {
            console.log('delete draw error >>', id);
        } finally {
            dispatch({ type: 'LOADING_END' });
        }
    }
}

export const addDraw = (data) => {
    return async (dispatch) => {
        try{
            dispatch({ type: 'LOADING_START'});
            const res = await axios
            .post(
                baseUrl + '/api/draw/new',
                data
            );
            dispatch({
                type: 'ADD_DRAW',
                palyload: res.data
            });

        } catch(error) {
            console.log('add a draw error');
        } finally {
            dispatch({ type: 'LOADING_END'});
        }
    }
}

export const addPrize = (prize) => {
    return async (dispatch) => {
        try{
            dispatch({type: 'LOADING_START'});
            const res = await axios
            .post(
                baseUrl + '/api/set/prize',
                prize
            );
            dispatch({
                type: 'SET_PRIZE',
                payload: res.data
            })

        } catch(error) {
            console.log('set prize error')
        } finally {
            dispatch({ type: 'LOADING_END'});
        }
    }
}

export const updatePrize = (prize) => {
    return async (dispatch) => {
        try{
            dispatch({type: 'LOADING_START'});
            const res = await axios
            .post(
                baseUrl + '/api/update/prize',
                prize
            );
            dispatch({
                type: 'UPDATE_PRIZE',
                payload: res.data
            })

        } catch(error) {
            console.log('set prize error')
        } finally {
            dispatch({ type: 'LOADING_END'});
        }
    }
}

export const updateReward = (prize) => {
    return async (dispatch) => {
        try{
            dispatch({type: 'LOADING_START'});
            const res = await axios
            .post(
                baseUrl + '/api/update/reward',
                prize
            );
            dispatch({
                type: 'UPDATE_REWARD',
                payload: res.data
            })

        } catch(error) {
            console.log('update reward error')
        } finally {
            dispatch({ type: 'LOADING_END'});
        }
    }
}

export const editDrawDate = (data) => {
    console.log("URL for editDrawDate", data)
    const url = baseUrl + '/api/draw/update/date/' + data.id;
    return async (dispatch) => {
        try{
            dispatch({ type: 'LOADING_START'});
            const res = await axios
            .put(
                url,
                data
            );
            dispatch({
                type: 'EDIT_DRAW_DATE',
                payload: res.data
            });
        } catch(error) {
            console.log('edit draw error');
        } finally {
            dispatch({ type: 'LOADING_END'});
        }
    }
}

export const getDraw = (id) => {
    return async (dispatch) => {
        try{
            dispatch({ type: 'LOADING_START'});
            const res = await axios
            .get(
                baseUrl + '/api/draw/${id}'
            );
            dispatch({
                type: 'GET_DRAW',
                payload: id
            });
        } catch(error) {
            console.log('get a draw error');
        } finally {
            dispatch({ type: 'LOADING_END'});
        }
    }
}

export const getDrawList = () => {
    return async (dispatch) => {
        try{
            dispatch({type:'LOADING_START'});
            const res = await axios
            .get(baseUrl + '/api/draw/all')
            dispatch({
                type: 'GET_DRAW_LISTS',
                payload: res.data
            })
        }catch(error) {
            console.log('get all draw error')
        }finally{
            dispatch({type: 'LOADING_END'});
        }
    }
}

export const getRewardList = () => {
    return async (dispatch) => {
        try{
            dispatch({type:'LOADING_START'})
            const res = await axios
            .get(baseUrl + '/api/reward/all')
            dispatch({
                type: 'GET_REWARD_LISTS',
                payload: res.data
            })
        }catch(error){
            console.log('get all rewards error');
        }finally{
            dispatch({type:'LOADING_END'});
        }
    }
}

export const addReward = (data) => {
    return async (dispatch) => {
        try{
            dispatch({ type: 'LOADING_START'});
            const res = await axios
            .post(
                baseUrl + '/api/reward/new',
                data
            );
            dispatch({
                type: 'ADD_REWARD',
                palyload: res.data
            });

        } catch(error) {
            console.log('add a draw error');
        } finally {
            dispatch({ type: 'LOADING_END'});
        }
    }
}
