import React from "react";
import {BrowserRouter, Route, Routes } from "react-router-dom"
import 'bootstrap/dist/css/bootstrap.min.css'
import { createStore, applyMiddleware} from "redux"
import reducer from "./reducer"
import thunk from "redux-thunk"
import { Provider } from "react-redux"
import AddDraw from "./AddDraw"
import DrawLists from "./DrawLists"
import PageNotFound from "./PageNotFound";
import Header from "./Header"
import EditDrawDate from "./EditDrawDate";
import EnterPrize from "./EnterPrize";
import EditPrize from "./EditPrize";
import RewardLists from "./RewardLists";
import AddReward from "./AddReward";
import EditReward from "./EditReward";


const middleware = [thunk]
const App = () => {
  const store = createStore(reducer, applyMiddleware(...middleware));
  return(
    <Provider store={store}>
      <div className="container">
        <BrowserRouter>
          <Header />
          <Routes>
            <Route  exact path="/edit/date/:id"
              element = {<EditDrawDate />} />
            <Route path="/add"
              element = {<AddDraw />} />
            <Route exact path="/"
              element = {<DrawLists />} />
            <Route element = {<PageNotFound />} />
            <Route exact path = "/enter/prize/:id" 
              element = {<EnterPrize />} />
            <Route exact path = "/edit/prize/:id"
              element = {<EditPrize />} />
            <Route exact path="/rewards" 
              element = {<RewardLists />}/>  
            <Route exact path="/add/reward"
              element = {<AddReward />} />
            <Route exact path="/edit/reward/:id"
              element = {<EditReward />}/>
          </Routes>
        </BrowserRouter>
      </div>
    </Provider>
  );
}

export default App;