import React,{Component} from "react";
import { connect } from 'react-redux'
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import * as action from './action'

class EditReward extends Component {
    state = {
        id: 0,
        name: "",
        price: 0
    }

    componentDidMount() {
        const id = Number(this.props.params.id);
        const reward_from_props = this.props.rewardFromStore.filter(item => item.value === id);
        if (reward_from_props.length > 0) {
            this.setState({
                id: id,
                name: reward_from_props[0].label,
                price: reward_from_props[0].price
            },
            () => { console.log("REWARD in state", this.state)}
            );
        }
    }

    onSubmitRewardForm = (data, event) => {
        event.preventDefault();
        if (data.name === null) {
            alert("จะต้องกำหนดชื่อของรางวัล");
            return;
        }
        if  (data.price === 0) {
            alert("จะต้องกำหนดมูลค่าของรางวัล");
            return;
        }
        const newReward = {
            id: data.id,
            name: data.name,
            price: data.price
        }
        this.props.updateRewardAtStore(newReward)

    }

    onNameChange = (event) => {
        this.setState(
            {
                "name":event.target.value
            }
        )
    }

    onPriceChange = (event) => {
        this.setState(
            {
                "price":event.target.value
            }
        )
    }


    render() {
        const {rewards, loading , completed} = this.props;
    
        if(completed) {
            this.props.navigate('/rewards');
        }

        if(loading) {
            return(
                <div className="col-12 mx-auto">
                    <div
                        className="alert-info text-center pt-5 pb-5"
                    >กำลังประมวลผล</div>
                </div>
    
            )    
        }

        return (
            <div className="row mt-3 mb-5 mx-auto bg-light">
                <div className="card col-12 col-sm-8 col-lg-6 mx-auto">
                    <form onSubmit={
                        this.onSubmitRewardForm.bind(this, this.state)
                    }>
                        <div className="block-4 text-center">
                            <img 
                                    className="img img-thumbnail mt-5 mb-3"
                                    src="https://ramitabeauty.com/wp-content/uploads/2020/06/logo-ramita-001r.png"
                                    alt="โลโก้"
                                />
                                <h4>แก้ไขรางวัล</h4>
                        </div>

                        <div className="form-group mt-1">
                              <label for="name">ชื่อของรางวัล</label>
                              <input className="form-control text-center" id="name" type="text"  onChange={this.onNameChange} value={this.state.name}/>
                        </div>

                        <div className="form-group mt-1 mb-1">
                              <label for="price">มูลค่าของรางวัล</label>
                              <input className="form-control text-center" id="price" type="number" onChange={this.onPriceChange} value={this.state.price}/>
                        </div>

                        <div className="form-group text-center pt-3">
                            <button
                                className="btn btn-outline-success btn-sm mx-1" 
                                onClick={() => { this.props.navigate('/rewards')}}
                                >
                                    ยกเลิก
                            </button>
                            <button
                                type="submit"
                                className="btn btn-sm btn-primary mx-1" >
                                    แก้ไข
                            </button>
                        </div>

                    </form>

                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        rewardFromStore: state.rewards,
        loading: state.isLoading,
        completed: state.isCompleted
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateRewardAtStore: (newRewardData) => {
            return dispatch(action.updateReward(newRewardData));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (
    function(props) {
        const params = useParams();
        const navigate = useNavigate();

        return <EditReward {...props} params ={params} navigate={navigate}/>
    }
)